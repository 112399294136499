import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/Seo'
import {
  Container,
  Box,
  Flex,
  Heading,
  useColorMode
} from '@chakra-ui/react'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import styled from '@emotion/styled'

const ImageBox = styled.div`
    transition: 0.5s ease;
    backface-visibility: hidden;
    position: relative; 
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
    &:hover {
        opacity: 0.5;
    }
`;

const Instagram = ({ data }) => {
    
    const { colorMode } = useColorMode()

//   const document = data.allInstagramContent?.edges
    
  return (
    <Layout>
      <Seo title="Willowcreek Design Group | Instagram" />
      <Container maxWidth="container.xl">
        <Box my="12">
            <Heading as="h1" fontSize="2.25rem" textTransform="uppercase" fontWeight="100" textAlign="center" mb={4}>
                Willow Creek On Instagram
            </Heading>
        </Box>
        <Box my="12">
            <Flex alignItems="center" flexWrap="wrap" w="100%" justifyContent="center">
                
                {/* {document && document.map((item, index) => (
                <Box w={{base: '100%', md: '33%', lg: '25%'}} p={2}>
                    <a href={item.node.permalink} target="_blank" rel="noopener noreferrer">
                <ImageBox position="relative">
                    
                    <GatsbyImage key={index} image={getImage(item.node.localFile)} alt="Instagram Image" />
                </ImageBox>
                </a>
                </Box>
            ))} */}
            </Flex>
        </Box>
    </Container>
    </Layout>
  )
}

// temporarlly disabled pending facebook review
 
// export const query = graphql`
//   {
//     allInstagramContent {
//       edges {
//         node {
//           localFile {
//             childImageSharp {
//               gatsbyImageData(aspectRatio: 1, layout: FULL_WIDTH, placeholder: BLURRED)
//             }
//           }
//           permalink
//         }
//       }
//     }
//   }
// `

export default Instagram;
